/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import React from 'react'
import { Helmet } from "react-helmet";
const faqs = [
  {
    question: "How can I reduce time doing payroll?",
    answer:
      "The best way to reduce your time completing payroll as well as increase accuracy and efficiency, is to hire a high end payroll provider."},
  {
    question: "What is the purpose of a bookkeeper in a small business?",
    answer:
      "The purpose of a bookkeeper in a small business is to ensure that efficient infrastructure is put in place to support workflows. A high end bookkeeping provider will also ensure tax compliance and on time filing and reporting. When tax returns are due, accountants require reconciled books in order to complete the filing. A high end bookkeeping provider will ensure that your books are reconciled and sent on time to the accountant."},
  {
    question: "What do online bookkeeping services offer to small businesses?",
    answer:
      "Online bookkeeping services offer a business a significantly reduced workload and piece of mind when it comes to their financial operations. A high end bookkeeping provider will ensure that an efficient financial infrastructure is put in place and manage it from the back-end. This will allow for the streamlining of the primary operations of the business while ensuring tax compliance."},
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <div className="bg-gray-50">
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
            "name": "How can I reduce time doing payroll?",
            "acceptedAnswer": {
              "@type": "Answer",
            "text": "<p>The best way to reduce your time completing payroll as well as increase accuracy and efficiency, is to hire a high end payroll provider.</p>"
                  }
                }, {
            "@type": "Question",
              "name": "What is the purpose of a bookkeeper in a small business?",
                "acceptedAnswer": {
              "@type": "Answer",
                "text": "<p>The purpose of a bookkeeper in a small business is to ensure that efficient infrastructure is put in place to support workflows. A high end bookkeeping provider will also ensure tax compliance and on time filing and reporting. When tax returns are due, accountants require reconciled books in order to complete the filing. A high end bookkeeping provider will ensure that your books are reconciled and sent on time to the accountant.<p>"
             }
           },{
            "@type": "Question",
              "name": "What do online bookkeeping services offer to small businesses?",
                "acceptedAnswer": {
              "@type": "Answer",
                "text": "<p>Online bookkeeping services offer a business a significantly reduced workload and piece of mind when it comes to their financial operations. A high end bookkeeping provider will ensure that an efficient financial infrastructure is put in place and manage it from the back-end. This will allow for the streamlining of the primary operations of the business while ensuring tax compliance.<p>"
             } 
          }]
              }`}
        </script>
      </Helmet>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">Frequently Asked Questions</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
