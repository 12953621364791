import React from "react";
import { Helmet } from 'react-helmet'
export default function Example() {
  return (
    <Helmet htmlAttributes={{
      lang: 'en',
    }}>
      <title>Bookkeeping Services | Certus Bookkeeping</title>
      <meta name="description" content="Bookkeeping Services for businesses throughout the GTA region. Work with us today to get started on your bookkeeping needs. " />
      <link rel="canonical" href="https://certusbookkeeping.com/bookkeeping-services" />
    </Helmet>
  );
}
