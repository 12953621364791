import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import CTA from "./CTA";
export default function Example() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h2>
            <span className="block text-base text-center text-theme font-semibold tracking-wide uppercase">
              Certus
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Bookkeeping Services Toronto
            </span>
          </h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            Financial management takes a great deal of time and effort. It
            requires knowledge, preparation, and energy. This can become a
            significant burden for business owners and individuals who have
            enough on their plate as it is.
          </p>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            The team at Certus Bookkeeping understands this and proudly offers
            professional bookkeeping services to both businesses and
            individuals. We are a team that has years of experience creating
            financial solutions that relieve businesses and individuals of this
            burden.
          </p>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            The team at Certus is an experienced group of professionals that has
            been providing high-quality service in the GTA for years. After
            identifying your needs during a consultation, we will create a
            custom tailored plan that will fill all of your financial
            requirements. Certus Bookkeeping is proud to offer the highest
            quality bookkeeping services in Toronto
          </p>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <h2>The Certus Process </h2>
          <p>
            Our process begins with a consultation in which we will review your
            business objectives and financial workflows. We will use this
            information to create a bookkeeping process that will ensure that
            all of your needs are met in an organized manner. Our services are
            custom tailored to fit each individual client's needs.
          </p>
          <p>
            If you are a small business owner that is growing your business we
            have plenty to offer. We have years of experience in helping small
            and medium-sized business owners set up their QuickBooks Online,
            prepare their financial statements, set up payroll processing, and
            assist them with their tax filings.
          </p>

          <p>
            We also offer professional bookkeeping services for individuals. We
            have been helping individuals in the GTA manage their finances for
            years. If you are an individual in need of bookkeeping services our
            plans offer receipt digitization & filing, expense tracking and
            customized financial reporting.
          </p>
        </div>
        <CTA />
        <div className="prose prose-indigo prose-lg text-gray-500 mx-auto">
          <h2>Why Choose Certus?</h2>
          <p>
            Getting the right bookkeeping and professional payroll services will
            make a massive difference for both your business and work-life
            balance. Our automated process will free up cashflow and time for
            your team. Certus Bookkeeping is proud to provide the highest
            quality payroll and bookkeeping services for companies of all sizes
            in Toronto.
          </p>
          <p>
            The Certus team holds ourselves to the highest standard of qualify
            and professionalism. This standard is why we are confident in our
            ability to help our clients with all of their financial management
            needs. After just one consultation, we will provide you with a
            detailed plan that will free up time, save you money, and relieve
            you of the stresses of bookkeeping.
          </p>
          <p>
            Every business is unique, which is why our plans are custom tailored
            to meet each client's specific needs. By working hand in hand with
            our clients we are able to achieve a gold standard of service.
          </p>
        </div>
      </div>
    </div>
  );
}
