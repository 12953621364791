/* This example requires Tailwind CSS v2.0+ */

import React from "react";
import Video from "../components/Video/Video";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import FAQ from "../page-sections/BookkeepingServices/Faq";
import Contact from "../page-sections/Contact/Contact";
import Body from "../page-sections/BookkeepingServices/Body";
import Features from "../components/Features/Features";
import SEO from "../components/SEO/Services/SEO"
export default function Example() {
  return (
    <div>
      
      <Header />
      <SEO />
      <Video />
      
     
      
      <FAQ />
      <Footer />
    </div>
  );
}
